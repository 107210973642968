/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/
:root {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* Additional Ionic Colors
// --------------------------------------------------
// In order to add colors to be used with Ionic components,
// the color should be added as a class with the convention `.ion-color-{COLOR}`
// where `{COLOR}` is the color to be used on the Ionic component
// and each variant is defined for the color. For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
*/

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}
/* 
.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
} */

.firebaseui-id-submit {
  background-color: var(--ion-color-primary) !important;
}

@media (max-width: 840px) {
  .desktop {
    display: none;
  }
}

@media (min-width: 840px) {
  .mobile {
    display: none;
  }
}
